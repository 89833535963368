import migrateDemoProfile from "~/utils/update/migrations/0.71.0/migrateDemoProfile"
import migrateProfile from "~/utils/update/migrations/0.71.0/migrateProfile"

export default function migrate_0_71_0(profile, updateContext) {
  const { isDemoProfile } = updateContext

  // Profiles.
  // Demo profiles are handled differently - they don't get UUIDs until they're imported.
  const migratedProfile = isDemoProfile
    ? migrateDemoProfile(profile)
    : migrateProfile(profile)

  // Mark as updated.
  migratedProfile.updateStatus.updated = true

  // Return migrated profile.
  return migratedProfile
}
