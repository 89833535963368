import migrateModifier from "~/utils/update/migrations/0.71.0/migrateModifier"

export default function migrateProfile(profile) {
  // console.log("Migrating profile", profile)

  // If a profile already has an ID and that ID is a number,
  // replace it with a new UUID.
  // If a profile has no ID, do nothing.
  // E.g. When importing profiles (UUIDs will be generated for these elsewhere).
  if (typeof profile.id === "number") {
    profile.id = crypto.randomUUID()
  }

  // Ensure profile has an orderSlain property.
  if (typeof profile.orderSlain === "undefined") {
    profile.orderSlain = {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    }
  }
  // Ensure profile has an abilitiesSelected property.
  if (typeof profile.abilitiesSelected === "undefined") {
    profile.abilitiesSelected = []
  }

  for (const unit of profile.units) {
    // Set points property to null if not present.
    if (typeof unit.points === "undefined") {
      unit.points = null
    }

    // Replace number unit IDs with UUIDs.
    if (typeof unit.id !== "string") {
      const unitUUID = crypto.randomUUID()
      // Update any references to this unit ID in profile.orderSlain.order.
      // Using filter() as there may be >1 orderSlain item with the same unit ID.
      const orderSlainItems = profile.orderSlain.order.filter(
        (item) => item.unitId === unit.id
      )
      if (orderSlainItems.length) {
        orderSlainItems.forEach((item) => {
          item.unitId = unitUUID
        })
      }
      unit.id = unitUUID
    }

    unit.abilities.forEach((ability) => {
      migrateModifier(ability, profile)
    })

    for (const modelType of unit.modelTypes) {
      // Replace number modelType IDs with UUIDs.
      if (typeof modelType.id !== "string") {
        const modelTypeUUID = crypto.randomUUID()
        // Update any references to this modelType ID in profile.orderSlain.order.
        // Using find() as there should only be 1 orderSlain item with the same modelType ID.
        const orderSlainItem = profile.orderSlain.order.find(
          (item) => item.modelTypeId === modelType.id
        )
        if (typeof orderSlainItem !== "undefined") {
          orderSlainItem.modelTypeId = modelTypeUUID
        }
        modelType.id = modelTypeUUID
      }

      // Set ignore & invuln properties to null if they are not present.
      if (typeof modelType.ignore === "undefined") {
        modelType.ignore = null
      }
      if (typeof modelType.invuln === "undefined") {
        modelType.invuln = null
      }

      if (typeof modelType.weapons === "undefined") {
        modelType.weapons = []
      }

      for (const weapon of modelType.weapons) {
        // Replace number weapon IDs with UUIDs.
        const weaponUUID = crypto.randomUUID()
        if (typeof weapon.id !== "string") {
          // Update any references to this weapon ID in profile.weaponsSelected.
          if (profile.weaponsSelected.melee.includes(weapon.id)) {
            const index = profile.weaponsSelected.melee.indexOf(weapon.id)
            profile.weaponsSelected.melee[index] = weaponUUID
          }
          if (profile.weaponsSelected.ranged.includes(weapon.id)) {
            const index = profile.weaponsSelected.ranged.indexOf(weapon.id)
            profile.weaponsSelected.ranged[index] = weaponUUID
          }
          weapon.id = weaponUUID
        }

        if (typeof weapon.abilities === "undefined") {
          weapon.abilities = []
        }
        weapon.abilities.forEach((ability) => {
          if (typeof ability.id !== "string") {
            migrateModifier(ability)
          }
        })

        delete weapon.abilityIdNext
      }
    }
  }

  delete profile.abilityIdNext
  delete profile.modelTypeIdNext
  delete profile.unitIdNext
  delete profile.weaponIdNext

  return profile
}
