// "Preparing" demo profiles generating & using UUIDs throughout the demo profiles before they are imported.
// Demo profiles don't have hardcoded UUIDs to avoid conflicts between different users' demo profiles when importing into the database.

function prepareDemoModifier(modifier, profile) {
  // Modifiers should use UUIDs as IDs.
  if (typeof modifier.id !== "string") {
    const modifierUUID = crypto.randomUUID()
    // Update profile.abilitiesSelected to use new modifier UUID.
    if (typeof profile !== "undefined") {
      if (profile.abilitiesSelected.includes(modifier.id)) {
        const index = profile.abilitiesSelected.indexOf(modifier.id)
        profile.abilitiesSelected[index] = modifierUUID
      }
    }
    modifier.id = modifierUUID
  }
  return modifier
}

export function prepareDemoProfiles(profiles) {
  profiles.forEach((profile) => {
    profile.id = crypto.randomUUID()

    // Units.
    for (const unit of profile.units) {
      // Replace number unit IDs with UUIDs.
      if (typeof unit.id !== "string") {
        const unitUUID = crypto.randomUUID()
        // Update any references to this unit ID in profile.orderSlain.order.
        // Using filter() as there may be >1 orderSlain item with the same unit ID.
        const orderSlainItems = profile.orderSlain.order.filter(
          (item) => item.unitId === unit.id
        )
        if (orderSlainItems.length) {
          orderSlainItems.forEach((item) => {
            item.unitId = unitUUID
          })
        }
        unit.id = unitUUID
      }

      // Unit abilities.
      unit.abilities.forEach((ability) => {
        prepareDemoModifier(ability, profile)
      })

      // ModelTypes.
      for (const modelType of unit.modelTypes) {
        // Replace number modelType IDs with UUIDs.
        if (typeof modelType.id !== "string") {
          const modelTypeUUID = crypto.randomUUID()
          // Update any references to this modelType ID in profile.orderSlain.order.
          // Using find() as there should only be 1 orderSlain item with the same modelType ID.
          const orderSlainItem = profile.orderSlain.order.find(
            (item) => item.modelTypeId === modelType.id
          )
          if (typeof orderSlainItem !== "undefined") {
            orderSlainItem.modelTypeId = modelTypeUUID
          }
          modelType.id = modelTypeUUID
        }

        // Weapons.
        for (const weapon of modelType.weapons) {
          // Replace number weapon IDs with UUIDs.
          const weaponUUID = crypto.randomUUID()
          if (typeof weapon.id !== "string") {
            // Update any references to this weapon ID in profile.weaponsSelected.
            if (profile.weaponsSelected.melee.includes(weapon.id)) {
              const index = profile.weaponsSelected.melee.indexOf(weapon.id)
              profile.weaponsSelected.melee[index] = weaponUUID
            }
            if (profile.weaponsSelected.ranged.includes(weapon.id)) {
              const index = profile.weaponsSelected.ranged.indexOf(weapon.id)
              profile.weaponsSelected.ranged[index] = weaponUUID
            }
            weapon.id = weaponUUID
          }

          // Weapon abilities.
          weapon.abilities.forEach((ability) => {
            if (typeof ability.id !== "string") {
              prepareDemoModifier(ability)
            }
          })
        }
      }
    }
  })
}
