export default function migrateModifier(modifier, profile) {
  // Modifiers should use UUIDs as IDs.

  if (typeof modifier.id !== "string") {
    const modifierUUID = crypto.randomUUID()

    // Update profile.abilitiesSelected to use new modifier UUID.
    if (typeof profile !== "undefined") {
      if (profile.abilitiesSelected.includes(modifier.id)) {
        const index = profile.abilitiesSelected.indexOf(modifier.id)
        profile.abilitiesSelected[index] = modifierUUID
      }
    }

    modifier.id = modifierUUID
  }

  return modifier
}
