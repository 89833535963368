export default function migrateDemoProfile(profile) {
  // Ensure profile has an orderSlain property.
  if (typeof profile.orderSlain === "undefined") {
    profile.orderSlain = {
      auto: true,
      order: [
        {
          modelTypeId: 1,
          modelTypeName: null,
          unitId: 1,
        },
      ],
    }
  }
  // Ensure profile has an abilitiesSelected property.
  if (typeof profile.abilitiesSelected === "undefined") {
    profile.abilitiesSelected = []
  }

  for (const unit of profile.units) {
    // Set points property to null if not present.
    if (typeof unit.points === "undefined") {
      unit.points = null
    }

    for (const modelType of unit.modelTypes) {
      // Set ignore & invuln properties to null if they are not present.
      if (typeof modelType.ignore === "undefined") {
        modelType.ignore = null
      }
      if (typeof modelType.invuln === "undefined") {
        modelType.invuln = null
      }
      // Ensure weapons property exists.
      if (typeof modelType.weapons === "undefined") {
        modelType.weapons = []
      }
      for (const weapon of modelType.weapons) {
        if (typeof weapon.abilities === "undefined") {
          weapon.abilities = []
        }
        delete weapon.abilityIdNext
      }
    }
  }

  delete profile.abilityIdNext
  delete profile.modelTypeIdNext
  delete profile.unitIdNext
  delete profile.weaponIdNext

  return profile
}
