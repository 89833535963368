import { useScriptGoogleAnalytics, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleAnalytics = useScriptGoogleAnalytics({"consent":{"mode":"advanced","defaults":{"analytics_storage":"denied","ad_storage":"denied","functionality_storage":"denied","personalization_storage":"denied","security_storage":"denied"}},"config":{"send_page_view":true,"debug_mode":false}})
    return { provide: { $scripts: { googleAnalytics } } }
  }
})