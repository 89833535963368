import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE from "/opt/build/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_daf8JLQYgNg5Oio6i3szlN9gBfCuQpCD32G5_g4EIgQ from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q from "/opt/build/repo/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/opt/build/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/opt/build/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms from "/opt/build/repo/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY from "/opt/build/repo/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8 from "/opt/build/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/opt/build/repo/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c from "/opt/build/repo/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/opt/build/repo/.nuxt/sentry-client-config.mjs";
import auth_redirect_HUzDltIkO2fpUO5JHjqMueRAOThb4ANNc2aslGKOTOY from "/opt/build/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w from "/opt/build/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o from "/opt/build/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg from "/opt/build/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import toast_client_aJM71VRKDdVxlg_WSmA3MY8SSpRRbbEgefE9DP_VLH0 from "/opt/build/repo/plugins/toast.client.ts";
import _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM from "/opt/build/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk from "/opt/build/repo/.nuxt/vuetify-nuxt-plugin.client.mjs";
import defaults_0Sn7xIMAzGkdbab2otVWD8mX4GpY74A3Jy_gY_4_qYk from "/opt/build/repo/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  vuetify_icons_uipcv7oAosTqHCLrDDbtENc9Xwt1PnnChKPXu5AVcjE,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  supabase_client_daf8JLQYgNg5Oio6i3szlN9gBfCuQpCD32G5_g4EIgQ,
  _0_siteConfig_tU0SxKrPeVRXWcGu2sOnIfhNDbYiKNfDCvYZhRueG0Q,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  vuetify_no_client_hints_1iRQgYFRR7xfuMumrkN4UGvWFXdQAoiR_NXI9qrJALY,
  plugin_MeUvTuoKUi51yb_kBguab6hdcExVXeTtZtTg9TZZBB8,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  auth_redirect_HUzDltIkO2fpUO5JHjqMueRAOThb4ANNc2aslGKOTOY,
  siteConfig_vuqmRkLAUZxQvb5pvUwT3uUdVggfjhj1m5v7Pb6IE0w,
  inferSeoMetaPlugin_KsEotgC9NJyW_guR_3z04hFN8TI2h5dgP8bzHmpMm5o,
  titles_Fth_MAhm7dgpxeTaMXibYXbcCjegjWK3QH9gKvbTRVg,
  toast_client_aJM71VRKDdVxlg_WSmA3MY8SSpRRbbEgefE9DP_VLH0,
  _0_routeRules_3p7F2AZYQSP_eJRsw5nLkf3zyZXPOFcTrXNpZlBwROM,
  vuetify_nuxt_plugin_client_DI1wm4qdqocRtY45JXrDvTzqqhOG2L83Lu4GY06gtpk,
  defaults_0Sn7xIMAzGkdbab2otVWD8mX4GpY74A3Jy_gY_4_qYk
]