import { cloneDeep } from "es-toolkit"
import { applyUpdate } from "~/utils/update/utils"
import migrateProfile_0_24_0 from "~/utils/update/migrations/0.24.0"
import migrateProfile_0_26_0 from "~/utils/update/migrations/0.26.0"
import migrateProfile_0_29_0 from "~/utils/update/migrations/0.29.0"
import migrateProfile_0_31_0 from "~/utils/update/migrations/0.31.0"
import migrateProfile_0_36_0 from "~/utils/update/migrations/0.36.0"
import migrateProfile_0_37_0 from "~/utils/update/migrations/0.37.0"
import migrateProfile_0_38_0 from "~/utils/update/migrations/0.38.0"
import migrateProfile_0_39_0 from "~/utils/update/migrations/0.39.0"
import migrateProfile_0_41_0 from "~/utils/update/migrations/0.41.0"
import migrateProfile_0_42_0 from "~/utils/update/migrations/0.42.0"
import migrateProfile_0_44_0 from "~/utils/update/migrations/0.44.0"
import migrateProfile_0_46_0 from "~/utils/update/migrations/0.46.0"
import migrateProfile_0_47_0 from "~/utils/update/migrations/0.47.0"
import migrateProfile_0_47_6 from "~/utils/update/migrations/0.47.6"
import migrateProfile_0_48_0 from "~/utils/update/migrations/0.48.0"
import migrateProfile_0_51_0 from "~/utils/update/migrations/0.51.0"
import migrateProfile_0_52_2 from "~/utils/update/migrations/0.52.2"
import migrateProfile_0_53_0 from "~/utils/update/migrations/0.53.0"
import migrateProfile_0_54_0 from "~/utils/update/migrations/0.54.0"
import migrateProfile_0_55_0 from "~/utils/update/migrations/0.55.0"
import migrateProfile_0_56_0 from "~/utils/update/migrations/0.56.0"
import migrateProfile_0_58_0 from "~/utils/update/migrations/0.58.0"
import migrateProfile_0_58_1 from "~/utils/update/migrations/0.58.1"
import migrateProfile_0_59_0 from "~/utils/update/migrations/0.59.0"
import migrateProfile_0_60_0 from "~/utils/update/migrations/0.60.0"
import migrateProfile_0_60_1 from "~/utils/update/migrations/0.60.1"
import migrateProfile_0_61_0 from "~/utils/update/migrations/0.61.0"
import migrateProfile_0_62_7 from "~/utils/update/migrations/0.62.7"
import migrateProfile_0_63_0 from "~/utils/update/migrations/0.63.0"
import migrateProfile_0_64_0 from "~/utils/update/migrations/0.64.0"
import migrateProfile_0_64_1 from "~/utils/update/migrations/0.64.1"
import migrateProfile_0_64_4 from "~/utils/update/migrations/0.64.4"
import migrateProfile_0_64_6 from "~/utils/update/migrations/0.64.6"
import migrateProfile_0_65_0 from "~/utils/update/migrations/0.65.0"
import migrateProfile_0_65_2 from "~/utils/update/migrations/0.65.2"
import migrateProfile_0_66_0 from "~/utils/update/migrations/0.66.0"
import migrateProfile_0_70_2 from "~/utils/update/migrations/0.70.2"
import migrateProfile_0_71_0 from "~/utils/update/migrations/0.71.0"

export default function updateProfile(inputProfile, updateContext) {
  // Returns a processed clone of the input profile
  // including an extra `updateStatus` property to track the profile's update status.

  // console.log("updateProfile()", updateContext)

  let profile = cloneDeep(inputProfile)

  // Add a temporary property to profile to track update status.
  // This will be deleted before the updated profile data is written back to the store.
  profile.updateStatus = {
    // Was the profile updated in any way.
    updated: false,
    // Was profile.weaponsSelected initialised as part of this update.
    weaponsSelectedInitialised: false,
  }

  // Run migrations.

  if (applyUpdate("0.24.0", updateContext)) {
    profile = migrateProfile_0_24_0(profile)
  }

  if (applyUpdate("0.26.0", updateContext)) {
    profile = migrateProfile_0_26_0(profile)
  }

  if (applyUpdate("0.29.0", updateContext)) {
    profile = migrateProfile_0_29_0(profile)
  }

  if (applyUpdate("0.31.0", updateContext)) {
    profile = migrateProfile_0_31_0(profile)
  }

  if (applyUpdate("0.36.0", updateContext)) {
    profile = migrateProfile_0_36_0(profile)
  }

  if (applyUpdate("0.37.0", updateContext)) {
    profile = migrateProfile_0_37_0(profile)
  }

  if (applyUpdate("0.38.0", updateContext)) {
    profile = migrateProfile_0_38_0(profile)
  }

  if (applyUpdate("0.39.0", updateContext)) {
    profile = migrateProfile_0_39_0(profile)
  }

  if (applyUpdate("0.41.0", updateContext)) {
    profile = migrateProfile_0_41_0(profile)
  }

  if (applyUpdate("0.42.0", updateContext)) {
    profile = migrateProfile_0_42_0(profile)
  }

  if (applyUpdate("0.44.0", updateContext)) {
    profile = migrateProfile_0_44_0(profile)
  }

  if (applyUpdate("0.46.0", updateContext)) {
    profile = migrateProfile_0_46_0(profile)
  }

  if (applyUpdate("0.47.0", updateContext)) {
    profile = migrateProfile_0_47_0(profile)
  }

  if (applyUpdate("0.47.6", updateContext)) {
    profile = migrateProfile_0_47_6(profile)
  }

  if (applyUpdate("0.48.0", updateContext)) {
    profile = migrateProfile_0_48_0(profile)
  }

  if (applyUpdate("0.51.0", updateContext)) {
    profile = migrateProfile_0_51_0(profile)
  }

  if (applyUpdate("0.52.2", updateContext)) {
    profile = migrateProfile_0_52_2(profile)
  }

  if (applyUpdate("0.53.0", updateContext)) {
    profile = migrateProfile_0_53_0(profile)
  }

  if (applyUpdate("0.54.0", updateContext)) {
    profile = migrateProfile_0_54_0(profile)
  }

  if (applyUpdate("0.55.0", updateContext)) {
    profile = migrateProfile_0_55_0(profile)
  }

  if (applyUpdate("0.56.0", updateContext)) {
    profile = migrateProfile_0_56_0(profile)
  }

  if (applyUpdate("0.58.0", updateContext)) {
    profile = migrateProfile_0_58_0(profile)
  }

  if (applyUpdate("0.58.1", updateContext)) {
    profile = migrateProfile_0_58_1(profile)
  }

  if (applyUpdate("0.59.0", updateContext)) {
    profile = migrateProfile_0_59_0(profile)
  }

  if (applyUpdate("0.60.0", updateContext)) {
    profile = migrateProfile_0_60_0(profile)
  }

  if (applyUpdate("0.60.1", updateContext)) {
    profile = migrateProfile_0_60_1(profile)
  }

  if (applyUpdate("0.61.0", updateContext)) {
    profile = migrateProfile_0_61_0(profile)
  }

  if (applyUpdate("0.62.7", updateContext)) {
    profile = migrateProfile_0_62_7(profile)
  }

  if (applyUpdate("0.63.0", updateContext)) {
    profile = migrateProfile_0_63_0(profile)
  }

  if (applyUpdate("0.64.0", updateContext)) {
    profile = migrateProfile_0_64_0(profile)
  }

  if (applyUpdate("0.64.1", updateContext)) {
    profile = migrateProfile_0_64_1(profile)
  }

  if (applyUpdate("0.64.2", updateContext)) {
    // Re-apply 0.64.1 migration.
    profile = migrateProfile_0_64_1(profile)
  }

  if (applyUpdate("0.64.4", updateContext)) {
    profile = migrateProfile_0_64_4(profile)
  }

  if (applyUpdate("0.64.5", updateContext)) {
    // Re-apply 0.64.4 migration.
    profile = migrateProfile_0_64_4(profile)
  }

  if (applyUpdate("0.64.6", updateContext)) {
    profile = migrateProfile_0_64_6(profile)
  }

  if (applyUpdate("0.65.0", updateContext)) {
    profile = migrateProfile_0_65_0(profile)
  }

  if (applyUpdate("0.65.2", updateContext)) {
    profile = migrateProfile_0_65_2(profile)
  }

  if (applyUpdate("0.66.0", updateContext)) {
    profile = migrateProfile_0_66_0(profile)
  }

  if (applyUpdate("0.70.2", updateContext)) {
    profile = migrateProfile_0_70_2(profile)
  }

  if (applyUpdate("0.71.0", updateContext)) {
    profile = migrateProfile_0_71_0(profile, updateContext)
  }

  // Sort root level profile properties before returning the profile.
  // Always keep the profile name as the first property though.
  return Object.keys(profile)
    .sort()
    .reduce((obj, key, currentIndex) => {
      // Ensure name if the first key.
      if (currentIndex === 0) {
        obj.name = profile.name
      }
      // All other properties are added back to the object alphabetically by key.
      if (obj[key] !== "name") {
        obj[key] = profile[key]
      }
      return obj
    }, {})
}
