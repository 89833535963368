
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexaAs6F0uOO1PbNxqH8DD_45e04gSZQCarVD9TCXJYV94KEMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93bxqMJRabVdWZXuvF9FWZmMIujcSvRxUBxs5JzQp4EB0Meta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as indexgxmW2RGjM93C2W9qrCEoKk4dSj_niO3ZRAkXfqr_45G8kMeta } from "/opt/build/repo/pages/faq/index.vue?macro=true";
import { default as loginEgw163CKw2092eoFJry_PRPlyDgzCW2aJLMIENZrRNAMeta } from "/opt/build/repo/pages/(auth)/login.vue?macro=true";
import { default as index1gA7Y5YUE43ys0Bl2nieWgrflxCUtZg6Mq9cvVA_K_45IMeta } from "/opt/build/repo/pages/account/index.vue?macro=true";
import { default as _91_46_46_46slug_93Br7V_45P10D89j4KLN1SitdlTP8n4WteooTbdPyYKcKk4Meta } from "/opt/build/repo/pages/faq/[...slug].vue?macro=true";
import { default as confirmataIydM2RGwEgvfXYzOWi_45ye9Fw66Y9v7ZRQjlDEsuUMeta } from "/opt/build/repo/pages/(auth)/confirm.vue?macro=true";
import { default as sign_45up6Rg8lnxXuuPFXpp_WqOtlHaIjbqUI7Vhm85V4WlKa8UMeta } from "/opt/build/repo/pages/(auth)/sign-up.vue?macro=true";
import { default as indexkxpSEzMFbbyWByBR3U3T5azVXLWnK0HfVWPVlMmItRgMeta } from "/opt/build/repo/pages/changelog/index.vue?macro=true";
import { default as manage_45profilesnA0DI97iXfNjTCxpG2jq_45DDKzLfHgi2MHuNnyWoIAy4Meta } from "/opt/build/repo/pages/manage-profiles.vue?macro=true";
import { default as send_45debug_45dataIl3IH3Ypf5SPSe5hNpJ7u0HxvdBRcFl58d5X_nkUK1IMeta } from "/opt/build/repo/pages/send-debug-data.vue?macro=true";
import { default as _91_46_46_46slug_93cM5vf6P1tUz8n9Hds_45otMt7shWS_qC66SrtM3c2yEVUMeta } from "/opt/build/repo/pages/changelog/[...slug].vue?macro=true";
import { default as modifier_45sandboxjdSRBaWm2FBdMWpuPtH3R3O47dJnWmZ0LOLHoHwiaukMeta } from "/opt/build/repo/pages/dev/modifier-sandbox.vue?macro=true";
import { default as reset_45passwordmRRMAJR1b3iVn5lARDDI75Az1cKJBkixt4i55J5jGZMMeta } from "/opt/build/repo/pages/(auth)/reset-password.vue?macro=true";
import { default as update_45passwordp9gVeUNOHsMhzY6FdG0O6JNG_45dMRFJ_45j3LsiWGod4qgMeta } from "/opt/build/repo/pages/account/update-password.vue?macro=true";
import { default as migrate_45preset_45profilesSn1gSkhno6Eex9zEWdwosbAw6McQyzrz3pTSFHv35v0Meta } from "/opt/build/repo/pages/dev/migrate-preset-profiles.vue?macro=true";
import { default as migrate_45preset_45modifiersvs3y98YoA3hagPEVczP0c9CFAHvt_45fRIGZQoL24aJBoMeta } from "/opt/build/repo/pages/dev/migrate-preset-modifiers.vue?macro=true";
import { default as component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93bxqMJRabVdWZXuvF9FWZmMIujcSvRxUBxs5JzQp4EB0Meta || {},
    component: () => import("/opt/build/repo/pages/[...slug].vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: indexgxmW2RGjM93C2W9qrCEoKk4dSj_niO3ZRAkXfqr_45G8kMeta || {},
    component: () => import("/opt/build/repo/pages/faq/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginEgw163CKw2092eoFJry_PRPlyDgzCW2aJLMIENZrRNAMeta || {},
    component: () => import("/opt/build/repo/pages/(auth)/login.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: index1gA7Y5YUE43ys0Bl2nieWgrflxCUtZg6Mq9cvVA_K_45IMeta || {},
    component: () => import("/opt/build/repo/pages/account/index.vue")
  },
  {
    name: "faq-slug",
    path: "/faq/:slug(.*)*",
    meta: _91_46_46_46slug_93Br7V_45P10D89j4KLN1SitdlTP8n4WteooTbdPyYKcKk4Meta || {},
    component: () => import("/opt/build/repo/pages/faq/[...slug].vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirmataIydM2RGwEgvfXYzOWi_45ye9Fw66Y9v7ZRQjlDEsuUMeta || {},
    component: () => import("/opt/build/repo/pages/(auth)/confirm.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45up6Rg8lnxXuuPFXpp_WqOtlHaIjbqUI7Vhm85V4WlKa8UMeta || {},
    component: () => import("/opt/build/repo/pages/(auth)/sign-up.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    meta: indexkxpSEzMFbbyWByBR3U3T5azVXLWnK0HfVWPVlMmItRgMeta || {},
    component: () => import("/opt/build/repo/pages/changelog/index.vue")
  },
  {
    name: "manage-profiles",
    path: "/manage-profiles",
    component: () => import("/opt/build/repo/pages/manage-profiles.vue")
  },
  {
    name: "send-debug-data",
    path: "/send-debug-data",
    meta: send_45debug_45dataIl3IH3Ypf5SPSe5hNpJ7u0HxvdBRcFl58d5X_nkUK1IMeta || {},
    component: () => import("/opt/build/repo/pages/send-debug-data.vue")
  },
  {
    name: "changelog-slug",
    path: "/changelog/:slug(.*)*",
    meta: _91_46_46_46slug_93cM5vf6P1tUz8n9Hds_45otMt7shWS_qC66SrtM3c2yEVUMeta || {},
    component: () => import("/opt/build/repo/pages/changelog/[...slug].vue")
  },
  {
    name: "dev-modifier-sandbox",
    path: "/dev/modifier-sandbox",
    component: () => import("/opt/build/repo/pages/dev/modifier-sandbox.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordmRRMAJR1b3iVn5lARDDI75Az1cKJBkixt4i55J5jGZMMeta || {},
    component: () => import("/opt/build/repo/pages/(auth)/reset-password.vue")
  },
  {
    name: "account-update-password",
    path: "/account/update-password",
    meta: update_45passwordp9gVeUNOHsMhzY6FdG0O6JNG_45dMRFJ_45j3LsiWGod4qgMeta || {},
    component: () => import("/opt/build/repo/pages/account/update-password.vue")
  },
  {
    name: "dev-migrate-preset-profiles",
    path: "/dev/migrate-preset-profiles",
    meta: migrate_45preset_45profilesSn1gSkhno6Eex9zEWdwosbAw6McQyzrz3pTSFHv35v0Meta || {},
    component: () => import("/opt/build/repo/pages/dev/migrate-preset-profiles.vue")
  },
  {
    name: "dev-migrate-preset-modifiers",
    path: "/dev/migrate-preset-modifiers",
    meta: migrate_45preset_45modifiersvs3y98YoA3hagPEVczP0c9CFAHvt_45fRIGZQoL24aJBoMeta || {},
    component: () => import("/opt/build/repo/pages/dev/migrate-preset-modifiers.vue")
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/intro",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/known-issues",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/mathhammer",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/many-vs-one",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  },
  {
    name: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0sMeta?.name,
    path: "/simulate-attack",
    component: component_45stubE86B9032rcV2J_cDe4Vtg3adsHBytwsL_y2ub6Qyo0s
  }
]