const adHocUi = {
  maxWeaponCount: 5,
}

const gameSystem = {
  version: {
    major: 10,
    ordinalIndicator: "th",
  },
}

const globalModifiers = {
  // Max number of global modifiers that can be created at any one time.
  maxGlobalModifiers: 6,
}

const profiles = {
  // Max number of weapons per model type.
  maxWeaponCount: 15,
  // Max number of profiles that can be saved.
  maxProfileCount: 200,
  // Max number of abilities per unit.
  maxUnitAbilities: 6,
  // Max number of abilities per weapon.
  maxWeaponAbilities: 6,
  // Max number of model types per unit.
  maxModelTypesPerUnit: 5,
  // Max number of units per profile.
  maxUnitsPerProfile: 5,
}

const sims = {
  // Max number of sims that can be run in one go.
  maxSims: 1000000,
  // Threshold for auto-running sims.
  maxAutoRunSims: 50000,
}

export {
  adHocUi as configAdHocUi,
  gameSystem as configGameSystem,
  globalModifiers as configGlobalModifiers,
  profiles as configProfiles,
  sims as configSims,
}
