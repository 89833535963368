import { cloneDeep, merge } from "es-toolkit"

const defaultWeapon = {
  name: null,
  nameUnique: null,
  countPerUnit: 1,
  type: null,
  attacks: null,
  bs: null,
  ws: null,
  strength: null,
  ap: 0,
  damage: null,
  abilities: [],
  abilityIdNext: 1,
  id: null,
}

function mergeDefaultsInToWeapon(weapon) {
  // Merges incoming weapon with a default weapon,
  // adding in any missing properties and setting their values.
  // Mutates the incoming weapon object.
  return merge(cloneDeep(defaultWeapon), weapon)
}

export { defaultWeapon, mergeDefaultsInToWeapon }
